'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var ReactDom = require("react-dom");
var App$ReasonTodomvc = require("./App.bs.js");

Css.$$global("html, body", /* :: */[
      Css.margin(/* zero */-789508312),
      /* :: */[
        Css.padding(/* zero */-789508312),
        /* [] */0
      ]
    ]);

Css.$$global("body", /* :: */[
      Css.fontFamily("'Helvetica Neue', Helvetica, Arial, sans-serif"),
      /* :: */[
        Css.fontSize(/* `px */[
              25096,
              14
            ]),
        /* :: */[
          Css.lineHeight(/* `em */[
                22632,
                1.4
              ]),
          /* :: */[
            Css.color(/* `hex */[
                  5194459,
                  "4d4d4d"
                ]),
            /* :: */[
              Css.background(/* `hex */[
                    5194459,
                    "f5f5f5"
                  ]),
              /* :: */[
                Css.minWidth(Css.px(230)),
                /* :: */[
                  Css.maxWidth(Css.px(550)),
                  /* :: */[
                    Css.margin2(/* zero */-789508312, /* auto */-1065951377),
                    /* :: */[
                      Css.fontWeight(/* `num */[
                            5496390,
                            300
                          ]),
                      /* :: */[
                        Css.unsafe("fontSmoothing", "antialiased"),
                        /* :: */[
                          Css.media("(min-width: 899px)", /* :: */[
                                Css.width(/* auto */-1065951377),
                                /* :: */[
                                  Css.paddingLeft(/* `px */[
                                        25096,
                                        300
                                      ]),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

Css.$$global("button", /* :: */[
      Css.margin(/* zero */-789508312),
      /* :: */[
        Css.padding(/* zero */-789508312),
        /* :: */[
          Css.borderStyle(/* none */-922086728),
          /* :: */[
            Css.background(/* none */-922086728),
            /* :: */[
              Css.verticalAlign(/* baseline */287825029),
              /* :: */[
                Css.fontFamily("inherit"),
                /* :: */[
                  Css.fontWeight(/* inherit_ */-601204732),
                  /* :: */[
                    Css.unsafe("appearance", "none"),
                    /* :: */[
                      Css.unsafe("fontSmoothing", "antialiased"),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var rootEl = document.getElementById("root");

if (rootEl.hasChildNodes()) {
  ReactDom.hydrate(React.createElement(App$ReasonTodomvc.make, { }), rootEl);
} else {
  ReactDom.render(React.createElement(App$ReasonTodomvc.make, { }), rootEl);
}

exports.rootEl = rootEl;
/*  Not a pure module */
