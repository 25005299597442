'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");

var container = Css.style(/* :: */[
      Css.margin4(/* `px */[
            25096,
            65
          ], /* auto */-1065951377, /* zero */-789508312, /* auto */-1065951377),
      /* :: */[
        Css.fontSize(/* `px */[
              25096,
              10
            ]),
        /* :: */[
          Css.color(/* `hex */[
                5194459,
                "bfbfbf"
              ]),
          /* :: */[
            Css.textAlign(/* center */98248149),
            /* :: */[
              Css.textShadow(Css.Shadow.text(undefined, /* `px */[
                        25096,
                        1
                      ], undefined, /* `rgba */[
                        -878128972,
                        /* tuple */[
                          255,
                          255,
                          255,
                          0.5
                        ]
                      ])),
              /* :: */[
                Css.selector("> p", /* :: */[
                      Css.lineHeight(/* `em */[
                            22632,
                            1.0
                          ]),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.selector("& a", /* :: */[
                        Css.color(/* currentColor */292050538),
                        /* :: */[
                          Css.fontWeight(/* `num */[
                                5496390,
                                400
                              ]),
                          /* :: */[
                            Css.textDecoration(/* none */-922086728),
                            /* :: */[
                              Css.hover(/* :: */[
                                    Css.textDecoration(/* underline */131142924),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  container: container
};

var make = React.memo((function (Props) {
        return React.createElement("footer", {
                    className: container
                  }, React.createElement("p", undefined, "Double-click to edit a todo"), React.createElement("p", undefined, "Created by ", React.createElement("a", {
                            href: "https://github.com/cometkim"
                          }, "cometkim")), React.createElement("p", undefined, "Part of ", React.createElement("a", {
                            href: "http://todomvc.com"
                          }, "TodoMVC")));
      }));

exports.Styles = Styles;
exports.make = make;
/* container Not a pure module */
