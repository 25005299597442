'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var container = Css.style(/* :: */[
      Css.width(Css.pct(100.0)),
      /* [] */0
    ]);

var input = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.margin(/* zero */-789508312),
        /* :: */[
          Css.padding4(/* `px */[
                25096,
                16
              ], /* `px */[
                25096,
                16
              ], /* `px */[
                25096,
                16
              ], /* `px */[
                25096,
                60
              ]),
          /* :: */[
            Css.borderStyle(/* none */-922086728),
            /* :: */[
              Css.width(Css.pct(100.0)),
              /* :: */[
                Css.fontSize(/* `px */[
                      25096,
                      24
                    ]),
                /* :: */[
                  Css.fontFamily("inherit"),
                  /* :: */[
                    Css.fontWeight(/* inherit_ */-601204732),
                    /* :: */[
                      Css.lineHeight(/* `em */[
                            22632,
                            1.4
                          ]),
                      /* :: */[
                        Css.boxSizing(/* borderBox */9307263),
                        /* :: */[
                          Css.background(/* `rgba */[
                                -878128972,
                                /* tuple */[
                                  0,
                                  0,
                                  0,
                                  0.003
                                ]
                              ]),
                          /* :: */[
                            Css.boxShadow(Css.Shadow.box(undefined, /* `px */[
                                      25096,
                                      -2
                                    ], /* `px */[
                                      25096,
                                      1
                                    ], undefined, true, /* `rgba */[
                                      -878128972,
                                      /* tuple */[
                                        0,
                                        0,
                                        0,
                                        0.03
                                      ]
                                    ])),
                            /* :: */[
                              Css.placeholder(/* :: */[
                                    Css.color(Css.hex("e6e6e6")),
                                    /* :: */[
                                      Css.fontStyle(/* italic */107228912),
                                      /* :: */[
                                        Css.fontWeight(/* `num */[
                                              5496390,
                                              300
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  container: container,
  input: input
};

var make = React.memo((function (Props) {
        var onSubmit = Props.onSubmit;
        var inputRef = React.useRef(null);
        var handleSubmit = React.useCallback((function ($$event) {
                $$event.preventDefault();
                Belt_Option.map(Caml_option.nullable_to_opt(inputRef.current), (function (inputRef) {
                        Curry._1(onSubmit, inputRef.value);
                        return (inputRef.value = '');
                      }));
                return /* () */0;
              }), [onSubmit]);
        return React.createElement("form", {
                    onSubmit: handleSubmit
                  }, React.createElement("input", {
                        ref: inputRef,
                        className: input,
                        id: "todo-input",
                        autoComplete: "off",
                        name: "todo-input",
                        placeholder: "What needs to be done?"
                      }));
      }));

exports.Styles = Styles;
exports.make = make;
/* container Not a pure module */
