'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function make(t) {
  return Curry._1(t, /* () */0);
}

exports.make = make;
/* No side effect */
