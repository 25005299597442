'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Model_AppState$ReasonTodomvc = require("../models/Model_AppState.bs.js");

var container = Css.style(/* :: */[
      Css.display(/* grid */-999565626),
      /* :: */[
        Css.gridTemplateColumns(/* :: */[
              Css.fr(1.0),
              /* :: */[
                Css.fr(2.0),
                /* :: */[
                  Css.fr(1.0),
                  /* [] */0
                ]
              ]
            ]),
        /* :: */[
          Css.height(/* `px */[
                25096,
                20
              ]),
          /* :: */[
            Css.padding2(/* `px */[
                  25096,
                  10
                ], /* `px */[
                  25096,
                  15
                ]),
            /* :: */[
              Css.color(/* `hex */[
                    5194459,
                    "777"
                  ]),
              /* :: */[
                Css.before(/* :: */[
                      Css.contentRule(""),
                      /* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.left(/* zero */-789508312),
                          /* :: */[
                            Css.right(/* zero */-789508312),
                            /* :: */[
                              Css.bottom(/* zero */-789508312),
                              /* :: */[
                                Css.height(/* `px */[
                                      25096,
                                      50
                                    ]),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* :: */[
                                    Css.unsafe("boxShadow", "\n          0 1px 1px rgba(0, 0, 0, 0.2),\n          0 8px 0 -3px #f6f6f6,\n          0 9px 1px -3px rgba(0, 0, 0, 0.2),\n          0 16px 0 -6px #f6f6f6,\n          0 17px 2px -6px rgba(0, 0, 0, 0.2)\n        "),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]),
                /* :: */[
                  Css.selector("> *", /* :: */[
                        Css.zIndex(1),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.selector("> :nth-child(1)", /* :: */[
                          Css.textAlign(/* left */-944764921),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.selector("> :nth-child(2)", /* :: */[
                            Css.textAlign(/* center */98248149),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.selector("> :nth-child(3)", /* :: */[
                              Css.textAlign(/* right */-379319332),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var counter = Css.style(/* [] */0);

var filters = Css.style(/* :: */[
      Css.margin(/* zero */-789508312),
      /* :: */[
        Css.padding(/* zero */-789508312),
        /* :: */[
          Css.listStyleType(/* none */-922086728),
          /* :: */[
            Css.selector("> li", /* :: */[
                  Css.display(/* inline */423610969),
                  /* :: */[
                    Css.selector("> a", /* :: */[
                          Css.margin2(/* `px */[
                                25096,
                                3
                              ], /* `px */[
                                25096,
                                5
                              ]),
                          /* :: */[
                            Css.padding2(/* `px */[
                                  25096,
                                  3
                                ], /* `px */[
                                  25096,
                                  7
                                ]),
                            /* :: */[
                              Css.color(/* currentColor */292050538),
                              /* :: */[
                                Css.textDecoration(/* none */-922086728),
                                /* :: */[
                                  Css.border(/* `px */[
                                        25096,
                                        1
                                      ], /* solid */12956715, /* transparent */582626130),
                                  /* :: */[
                                    Css.borderRadius(/* `px */[
                                          25096,
                                          3
                                        ]),
                                    /* :: */[
                                      Css.hover(/* :: */[
                                            Css.borderColor(/* `rgba */[
                                                  -878128972,
                                                  /* tuple */[
                                                    175,
                                                    47,
                                                    47,
                                                    0.1
                                                  ]
                                                ]),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        Css.selector("&.selected", /* :: */[
                                              Css.borderColor(/* `rgba */[
                                                    -878128972,
                                                    /* tuple */[
                                                      175,
                                                      47,
                                                      47,
                                                      0.2
                                                    ]
                                                  ]),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var clearComplete = Css.style(/* :: */[
      Css.fontSize(/* `px */[
            25096,
            14
          ]),
      /* :: */[
        Css.color(/* currentColor */292050538),
        /* :: */[
          Css.cursor(/* pointer */-786317123),
          /* :: */[
            Css.lineHeight(/* `px */[
                  25096,
                  20
                ]),
            /* :: */[
              Css.hover(/* :: */[
                    Css.textDecoration(/* underline */131142924),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  container: container,
  counter: counter,
  filters: filters,
  clearComplete: clearComplete
};

var make = React.memo((function (Props) {
        var todos = Props.todos;
        return React.createElement("div", undefined, String(Belt_List.length(Belt_List.keep(todos, (function (todo) {
                                  return !todo.complete;
                                })))) + " items left");
      }));

var TodoLeftCounter = {
  make: make
};

var make$1 = React.memo((function (Props) {
        var url = ReasonReactRouter.useUrl(undefined, /* () */0);
        return React.createElement("ul", {
                    className: filters
                  }, React.createElement("li", undefined, React.createElement("a", {
                            className: "" === url.hash ? "selected" : "",
                            href: "#"
                          }, "All")), React.createElement("li", undefined, React.createElement("a", {
                            className: "active" === url.hash ? "selected" : "",
                            href: "#active"
                          }, "Active")), React.createElement("li", undefined, React.createElement("a", {
                            className: "completed" === url.hash ? "selected" : "",
                            href: "#completed"
                          }, "Completed")));
      }));

var TodoFilters = {
  make: make$1
};

var make$2 = React.memo((function (Props) {
        var hidden = Props.hidden;
        var dispatch = Model_AppState$ReasonTodomvc.Dispatch.use(/* () */0);
        return React.createElement("div", {
                    hidden: hidden
                  }, React.createElement("button", {
                        className: clearComplete,
                        onClick: (function (param) {
                            return Curry._1(dispatch, /* ClearCompleted */0);
                          })
                      }, "Clear completed"));
      }));

var ClearButton = {
  make: make$2
};

var make$3 = React.memo((function (Props) {
        var todos = Props.todos;
        var hasCompleted = Belt_List.length(Belt_List.keep(todos, (function (todo) {
                    return todo.complete;
                  }))) > 0;
        return React.createElement("div", {
                    className: container
                  }, React.createElement(make, {
                        todos: todos
                      }), React.createElement(make$1, { }), React.createElement(make$2, {
                        hidden: !hasCompleted
                      }));
      }));

var AppState = /* alias */0;

var Todo = /* alias */0;

exports.AppState = AppState;
exports.Todo = Todo;
exports.Styles = Styles;
exports.TodoLeftCounter = TodoLeftCounter;
exports.TodoFilters = TodoFilters;
exports.ClearButton = ClearButton;
exports.make = make$3;
/* container Not a pure module */
