'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var CheckedSvg = require("../assets/checked.svg");
var UncheckedSvg = require("../assets/unchecked.svg");
var Model_AppState$ReasonTodomvc = require("../models/Model_AppState.bs.js");

var checkedSrc = CheckedSvg.default;

var uncheckedSrc = UncheckedSvg.default;

var view = Css.style(/* :: */[
      Css.display(/* block */888960333),
      /* :: */[
        Css.width(Css.pct(100.0)),
        /* :: */[
          Css.hover(/* :: */[
                Css.selector("> button", /* :: */[
                      Css.display(Css.block),
                      /* [] */0
                    ]),
                /* [] */0
              ]),
          /* :: */[
            Css.borderBottom(/* `px */[
                  25096,
                  1
                ], /* solid */12956715, /* `hex */[
                  5194459,
                  "ededed"
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var edit = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.margin(/* zero */-789508312),
        /* :: */[
          Css.marginLeft(/* `px */[
                25096,
                43
              ]),
          /* :: */[
            Css.width(Css.pct(100.0)),
            /* :: */[
              Css.fontSize(/* `px */[
                    25096,
                    24
                  ]),
              /* :: */[
                Css.fontFamily("inherit"),
                /* :: */[
                  Css.fontWeight(/* inherit_ */-601204732),
                  /* :: */[
                    Css.lineHeight(/* `em */[
                          22632,
                          1.4
                        ]),
                    /* :: */[
                      Css.color(/* currentColor */292050538),
                      /* :: */[
                        Css.padding2(/* `px */[
                              25096,
                              12
                            ], /* `px */[
                              25096,
                              16
                            ]),
                        /* :: */[
                          Css.border(/* `px */[
                                25096,
                                1
                              ], /* solid */12956715, /* `hex */[
                                5194459,
                                "999"
                              ]),
                          /* :: */[
                            Css.borderBottomStyle(/* none */-922086728),
                            /* :: */[
                              Css.boxShadow(Css.Shadow.box(undefined, /* `px */[
                                        25096,
                                        -1
                                      ], /* `px */[
                                        25096,
                                        5
                                      ], undefined, true, /* `rgba */[
                                        -878128972,
                                        /* tuple */[
                                          0,
                                          0,
                                          0,
                                          0.2
                                        ]
                                      ])),
                              /* :: */[
                                Css.boxSizing(/* borderBox */9307263),
                                /* :: */[
                                  Css.unsafe("fontSmoothing", "antialiased"),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var toggle = Css.style(/* :: */[
      Css.opacity(0.0),
      /* :: */[
        Css.textAlign(/* center */98248149),
        /* :: */[
          Css.width(/* `px */[
                25096,
                40
              ]),
          /* :: */[
            Css.height(/* `px */[
                  25096,
                  40
                ]),
            /* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.top(/* zero */-789508312),
                /* :: */[
                  Css.bottom(/* zero */-789508312),
                  /* :: */[
                    Css.margin2(/* auto */-1065951377, /* zero */-789508312),
                    /* :: */[
                      Css.borderStyle(/* none */-922086728),
                      /* :: */[
                        Css.background(/* none */-922086728),
                        /* :: */[
                          Css.selector("& + label", /* :: */[
                                Css.backgroundImage(/* `url */[
                                      5843823,
                                      uncheckedSrc
                                    ]),
                                /* :: */[
                                  Css.backgroundRepeat(/* noRepeat */-695430532),
                                  /* :: */[
                                    Css.unsafe("backgroundPosition", "center left"),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* :: */[
                            Css.selector("&:checked + label", /* :: */[
                                  Css.backgroundImage(/* `url */[
                                        5843823,
                                        checkedSrc
                                      ]),
                                  /* :: */[
                                    Css.color(/* `hex */[
                                          5194459,
                                          "d9d9d9"
                                        ]),
                                    /* :: */[
                                      Css.textDecoration(/* lineThrough */412155569),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var text = Css.style(/* :: */[
      Css.display(/* block */888960333),
      /* :: */[
        Css.wordBreak(/* breakAll */-323760734),
        /* :: */[
          Css.lineHeight(/* `em */[
                22632,
                1.2
              ]),
          /* :: */[
            Css.padding4(/* `px */[
                  25096,
                  15
                ], /* `px */[
                  25096,
                  15
                ], /* `px */[
                  25096,
                  15
                ], /* `px */[
                  25096,
                  60
                ]),
            /* :: */[
              Css.transition(400, undefined, undefined, "color"),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var destroy = Css.style(/* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.position(/* absolute */-1013592457),
        /* :: */[
          Css.top(/* zero */-789508312),
          /* :: */[
            Css.right(/* `px */[
                  25096,
                  10
                ]),
            /* :: */[
              Css.bottom(/* zero */-789508312),
              /* :: */[
                Css.width(/* `px */[
                      25096,
                      40
                    ]),
                /* :: */[
                  Css.height(/* `px */[
                        25096,
                        40
                      ]),
                  /* :: */[
                    Css.margin2(/* auto */-1065951377, /* zero */-789508312),
                    /* :: */[
                      Css.marginBottom(/* `px */[
                            25096,
                            11
                          ]),
                      /* :: */[
                        Css.fontSize(/* `px */[
                              25096,
                              30
                            ]),
                        /* :: */[
                          Css.color(/* `hex */[
                                5194459,
                                "cc9a9a"
                              ]),
                          /* :: */[
                            Css.transition(200, undefined, /* easeOut */715748672, "color"),
                            /* :: */[
                              Css.hover(/* :: */[
                                    Css.color(/* `hex */[
                                          5194459,
                                          "af5b5e"
                                        ]),
                                    /* [] */0
                                  ]),
                              /* :: */[
                                Css.after(/* :: */[
                                      Css.contentRule("×"),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var completed = Css.style(/* :: */[
      Css.textDecoration(Css.lineThrough),
      /* [] */0
    ]);

var hidden = Css.style(/* :: */[
      Css.display(Css.none),
      /* [] */0
    ]);

var Styles = {
  view: view,
  edit: edit,
  toggle: toggle,
  text: text,
  destroy: destroy,
  completed: completed,
  hidden: hidden
};

var make = React.memo((function (Props) {
        var todo = Props.todo;
        var dispatch = Model_AppState$ReasonTodomvc.Dispatch.use(/* () */0);
        var url = ReasonReactRouter.useUrl(undefined, /* () */0);
        var match = todo.complete;
        var match$1 = url.hash;
        var hidden$1 = match ? (
            match$1 === "active" ? true : false
          ) : (
            match$1 === "completed" ? true : false
          );
        var handleToggle = function (param) {
          return Curry._1(dispatch, /* ToggleTodo */Block.__(2, [todo.id]));
        };
        var handleDestroy = function (param) {
          return Curry._1(dispatch, /* DestroyTodo */Block.__(4, [todo.id]));
        };
        var inputRef = React.useRef(null);
        var match$2 = React.useState((function () {
                return false;
              }));
        var setEditing = match$2[1];
        var editing = match$2[0];
        var editStart = function (param) {
          return Curry._1(setEditing, (function (param) {
                        return true;
                      }));
        };
        var editEnd = function (param) {
          return Curry._1(setEditing, (function (param) {
                        return false;
                      }));
        };
        var onKeyDown = function ($$event) {
          if ($$event.key === "Enter") {
            return Curry._1(setEditing, (function (param) {
                          return false;
                        }));
          } else {
            return 0;
          }
        };
        React.useEffect((function () {
                if (editing) {
                  Belt_Option.map(Caml_option.nullable_to_opt(inputRef.current), (function (el) {
                          return el.focus();
                        }));
                }
                return ;
              }), [editing]);
        React.useEffect((function () {
                var match = inputRef.current;
                var text = editing || (match == null) ? todo.text : match.value;
                if (text !== "" && text !== todo.text) {
                  Curry._1(dispatch, /* ChangeText */Block.__(5, [
                          todo.id,
                          text
                        ]));
                }
                return ;
              }), /* tuple */[
              todo,
              editing
            ]);
        return React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: Css.merge(/* :: */[
                              view,
                              /* :: */[
                                hidden$1 ? hidden : "",
                                /* :: */[
                                  editing ? hidden : "",
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, React.createElement("input", {
                            className: toggle,
                            checked: todo.complete,
                            type: "checkbox",
                            onChange: handleToggle
                          }), React.createElement("label", {
                            className: text,
                            onDoubleClick: editStart
                          }, todo.text), React.createElement("button", {
                            className: destroy,
                            onClick: handleDestroy
                          })), React.createElement("input", {
                        ref: inputRef,
                        defaultValue: todo.text,
                        className: Css.merge(/* :: */[
                              edit,
                              /* :: */[
                                hidden$1 ? hidden : "",
                                /* :: */[
                                  editing ? "" : hidden,
                                  /* [] */0
                                ]
                              ]
                            ]),
                        autoFocus: true,
                        onKeyDown: onKeyDown,
                        onBlur: editEnd
                      }));
      }));

var AppState = /* alias */0;

var Todo = /* alias */0;

exports.checkedSrc = checkedSrc;
exports.uncheckedSrc = uncheckedSrc;
exports.AppState = AppState;
exports.Todo = Todo;
exports.Styles = Styles;
exports.make = make;
/* checkedSrc Not a pure module */
