'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var TodoItem$ReasonTodomvc = require("./TodoItem.bs.js");
var Model_AppState$ReasonTodomvc = require("../models/Model_AppState.bs.js");

var container = Css.style(/* :: */[
      Css.listStyleType(Css.none),
      /* :: */[
        Css.margin(Css.zero),
        /* :: */[
          Css.paddingLeft(Css.zero),
          /* [] */0
        ]
      ]
    ]);

var itemWrapper = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.fontSize(/* `px */[
              25096,
              24
            ]),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.alignItems(Css.center),
            /* [] */0
          ]
        ]
      ]
    ]);

var toggleAll = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.textAlign(/* center */98248149),
        /* :: */[
          Css.borderStyle(/* none */-922086728),
          /* :: */[
            Css.opacity(0.0),
            /* :: */[
              Css.selector("& + label", /* :: */[
                    Css.position(/* absolute */-1013592457),
                    /* :: */[
                      Css.top(/* `px */[
                            25096,
                            -52
                          ]),
                      /* :: */[
                        Css.left(/* `px */[
                              25096,
                              -13
                            ]),
                        /* :: */[
                          Css.width(/* `px */[
                                25096,
                                60
                              ]),
                          /* :: */[
                            Css.height(/* `px */[
                                  25096,
                                  34
                                ]),
                            /* :: */[
                              Css.fontSize(/* zero */-789508312),
                              /* :: */[
                                Css.transform(/* `rotate */[
                                      -887077285,
                                      /* `deg */[
                                        4995526,
                                        90.0
                                      ]
                                    ]),
                                /* :: */[
                                  Css.before(/* :: */[
                                        Css.contentRule("❯"),
                                        /* :: */[
                                          Css.fontSize(/* `px */[
                                                25096,
                                                22
                                              ]),
                                          /* :: */[
                                            Css.color(/* `hex */[
                                                  5194459,
                                                  "e6e6e6"
                                                ]),
                                            /* :: */[
                                              Css.padding2(/* `px */[
                                                    25096,
                                                    10
                                                  ], /* `px */[
                                                    25096,
                                                    27
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                Css.selector("&:checked + label", /* :: */[
                      Css.before(/* :: */[
                            Css.color(/* `hex */[
                                  5194459,
                                  "737373"
                                ]),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  container: container,
  itemWrapper: itemWrapper,
  toggleAll: toggleAll
};

var make = React.memo((function (Props) {
        var checked = Props.checked;
        var dispatch = Model_AppState$ReasonTodomvc.Dispatch.use(/* () */0);
        return React.createElement(React.Fragment, undefined, React.createElement("input", {
                        className: toggleAll,
                        id: "toggle-all",
                        checked: checked,
                        type: "checkbox",
                        onChange: (function ($$event) {
                            var checked = $$event.target.checked;
                            return Curry._1(dispatch, /* ToggleAll */Block.__(3, [checked]));
                          })
                      }), React.createElement("label", {
                        htmlFor: "toggle-all"
                      }));
      }));

var ToggleAll = {
  make: make
};

function TodoList(Props) {
  var todos = Props.todos;
  var checked = Belt_List.every(todos, (function (todo) {
          return todo.complete;
        }));
  return React.createElement(React.Fragment, undefined, React.createElement(make, {
                  checked: checked
                }), React.createElement("ul", {
                  className: container
                }, Belt_List.toArray(Belt_List.map(todos, (function (todo) {
                            return React.createElement("li", {
                                        key: todo.id,
                                        className: itemWrapper
                                      }, React.createElement(TodoItem$ReasonTodomvc.make, {
                                            todo: todo
                                          }));
                          })))));
}

var AppState = /* alias */0;

var Todo = /* alias */0;

var make$1 = TodoList;

exports.AppState = AppState;
exports.Todo = Todo;
exports.Styles = Styles;
exports.ToggleAll = ToggleAll;
exports.make = make$1;
/* container Not a pure module */
