'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Constant$ReasonTodomvc = require("../Constant.bs.js");

var container = Css.style(/* :: */[
      Css.media("(min-width: 899px)", /* :: */[
            Css.left(/* `px */[
                  25096,
                  8
                ]),
            /* [] */0
          ]),
      /* :: */[
        Css.position(/* absolute */-1013592457),
        /* :: */[
          Css.top(/* `px */[
                25096,
                8
              ]),
          /* :: */[
            Css.left(/* `px */[
                  25096,
                  -300
                ]),
            /* :: */[
              Css.width(/* `px */[
                    25096,
                    272
                  ]),
              /* :: */[
                Css.padding(/* `px */[
                      25096,
                      10
                    ]),
                /* :: */[
                  Css.borderRadius(/* `px */[
                        25096,
                        5
                      ]),
                  /* :: */[
                    Css.backgroundColor(/* `rgba */[
                          -878128972,
                          /* tuple */[
                            255,
                            255,
                            255,
                            0.6
                          ]
                        ]),
                    /* :: */[
                      Css.transitionProperty("left"),
                      /* :: */[
                        Css.transitionDuration(500),
                        /* :: */[
                          Css.selector("h3, h4, h5", /* :: */[
                                Css.margin2(/* `px */[
                                      25096,
                                      10
                                    ], /* zero */-789508312),
                                /* :: */[
                                  Css.fontWeight(/* `num */[
                                        5496390,
                                        500
                                      ]),
                                  /* :: */[
                                    Css.lineHeight(/* `em */[
                                          22632,
                                          1.2
                                        ]),
                                    /* :: */[
                                      Css.color(Css.hex("000")),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]),
                          /* :: */[
                            Css.selector("h3", /* :: */[
                                  Css.fontSize(Css.px(24)),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.selector("h4", /* :: */[
                                    Css.fontSize(Css.px(18)),
                                    /* [] */0
                                  ]),
                              /* :: */[
                                Css.selector("h5", /* :: */[
                                      Css.marginBottom(/* zero */-789508312),
                                      /* :: */[
                                        Css.fontSize(Css.px(14)),
                                        /* [] */0
                                      ]
                                    ]),
                                /* :: */[
                                  Css.selector("hr", /* :: */[
                                        Css.margin2(/* `px */[
                                              25096,
                                              20
                                            ], /* zero */-789508312),
                                        /* :: */[
                                          Css.borderStyle(/* none */-922086728),
                                          /* :: */[
                                            Css.borderTop(/* `px */[
                                                  25096,
                                                  1
                                                ], /* dashed */568403505, /* `hex */[
                                                  5194459,
                                                  "c5c5c5"
                                                ]),
                                            /* :: */[
                                              Css.borderBottom(/* `px */[
                                                    25096,
                                                    1
                                                  ], /* dashed */568403505, /* `hex */[
                                                    5194459,
                                                    "f7f7f7"
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.selector("a", /* :: */[
                                          Css.fontWeight(/* normal */812216871),
                                          /* :: */[
                                            Css.textDecoration(/* none */-922086728),
                                            /* :: */[
                                              Css.color(/* `hex */[
                                                    5194459,
                                                    "b83f45"
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    /* :: */[
                                      Css.selector("ul", /* :: */[
                                            Css.padding(/* zero */-789508312),
                                            /* :: */[
                                              Css.marginBottom(/* `px */[
                                                    25096,
                                                    30
                                                  ]),
                                              /* :: */[
                                                Css.marginLeft(/* `px */[
                                                      25096,
                                                      25
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  container: container
};

function Sidebar$SourceLinks(Props) {
  return React.createElement("span", undefined, React.createElement("h5", undefined, "Example"), React.createElement("a", {
                  href: Constant$ReasonTodomvc.home
                }, "Source"));
}

var SourceLinks = {
  make: Sidebar$SourceLinks
};

function Sidebar$SpeechBubble(Props) {
  return React.createElement("blockquote", {
              className: Css.style(/* :: */[
                    Css.position(/* relative */903134412),
                    /* :: */[
                      Css.padding(/* `px */[
                            25096,
                            10
                          ]),
                      /* :: */[
                        Css.backgroundColor(/* `rgba */[
                              -878128972,
                              /* tuple */[
                                0,
                                0,
                                0,
                                0.04
                              ]
                            ]),
                        /* :: */[
                          Css.borderRadius(/* `px */[
                                25096,
                                5
                              ]),
                          /* :: */[
                            Css.borderStyle(/* none */-922086728),
                            /* :: */[
                              Css.margin4(/* `px */[
                                    25096,
                                    20
                                  ], /* zero */-789508312, /* `px */[
                                    25096,
                                    60
                                  ], /* zero */-789508312),
                              /* :: */[
                                Css.after(/* :: */[
                                      Css.contentRule(""),
                                      /* :: */[
                                        Css.position(/* absolute */-1013592457),
                                        /* :: */[
                                          Css.top(Css.pct(100.0)),
                                          /* :: */[
                                            Css.right(/* `px */[
                                                  25096,
                                                  30
                                                ]),
                                            /* :: */[
                                              Css.border(/* `px */[
                                                    25096,
                                                    13
                                                  ], /* solid */12956715, /* transparent */582626130),
                                              /* :: */[
                                                Css.borderTopColor(/* `rgba */[
                                                      -878128972,
                                                      /* tuple */[
                                                        0,
                                                        0,
                                                        0,
                                                        0.04
                                                      ]
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement("p", {
                  className: Css.style(/* :: */[
                        Css.fontSize(/* `px */[
                              25096,
                              15
                            ]),
                        /* :: */[
                          Css.fontWeight(/* `num */[
                                5496390,
                                300
                              ]),
                          /* :: */[
                            Css.fontStyle(/* italic */107228912),
                            /* :: */[
                              Css.marginTop(/* zero */-789508312),
                              /* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.before(/* :: */[
                                        Css.contentRule("“"),
                                        /* :: */[
                                          Css.position(/* absolute */-1013592457),
                                          /* :: */[
                                            Css.top(/* `px */[
                                                  25096,
                                                  -20
                                                ]),
                                            /* :: */[
                                              Css.left(/* `px */[
                                                    25096,
                                                    3
                                                  ]),
                                              /* :: */[
                                                Css.fontSize(/* `px */[
                                                      25096,
                                                      50
                                                    ]),
                                                /* :: */[
                                                  Css.opacity(0.15),
                                                  /* :: */[
                                                    Css.height(/* `px */[
                                                          25096,
                                                          65
                                                        ]),
                                                    /* :: */[
                                                      Css.display(/* flex */-1010954439),
                                                      /* :: */[
                                                        Css.alignItems(/* center */98248149),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.after(/* :: */[
                                          Css.contentRule("”"),
                                          /* :: */[
                                            Css.position(/* absolute */-1013592457),
                                            /* :: */[
                                              Css.bottom(/* `px */[
                                                    25096,
                                                    -42
                                                  ]),
                                              /* :: */[
                                                Css.right(/* `px */[
                                                      25096,
                                                      3
                                                    ]),
                                                /* :: */[
                                                  Css.fontSize(/* `px */[
                                                        25096,
                                                        50
                                                      ]),
                                                  /* :: */[
                                                    Css.opacity(0.15),
                                                    /* :: */[
                                                      Css.height(/* `px */[
                                                            25096,
                                                            65
                                                          ]),
                                                      /* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.alignItems(/* center */98248149),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])
                }, "\n          ReasonReact is a safer, simpler way to build React components, in Reason.\n        "), React.createElement("footer", {
                  className: Css.style(/* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.bottom(/* `px */[
                                25096,
                                -40
                              ]),
                          /* :: */[
                            Css.right(/* zero */-789508312),
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement("a", undefined, "ReasonReact")));
}

var SpeechBubble = {
  make: Sidebar$SpeechBubble
};

function Sidebar$OfficialResourceLinks(Props) {
  return React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement("a", {
                      href: "https://reasonml.github.io/reason-react/docs/en/what-and-why"
                    }, "What & Why")), React.createElement("li", undefined, React.createElement("a", {
                      href: "https://reasonml.github.io/reason-react/docs/en/simple"
                    }, "Examples")));
}

var OfficialResourceLinks = {
  make: Sidebar$OfficialResourceLinks
};

function Sidebar$CommunityLinks(Props) {
  return React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement("a", {
                      href: "https://reasonml.chat"
                    }, "Forum")), React.createElement("li", undefined, React.createElement("a", {
                      href: "https://discord.gg/reasonml"
                    }, "Discord")), React.createElement("li", undefined, React.createElement("a", {
                      href: "https://twitter.com/reasonml"
                    }, "Twitter")), React.createElement("li", undefined, React.createElement("a", {
                      href: "https://www.reddit.com/r/reasonml/"
                    }, "Reddit")), React.createElement("li", undefined, React.createElement("a", {
                      href: "http://stackoverflow.com/questions/tagged/reason-react"
                    }, "Stack Overflow")));
}

var CommunityLinks = {
  make: Sidebar$CommunityLinks
};

var make = React.memo((function (Props) {
        return React.createElement("aside", {
                    className: container
                  }, React.createElement("header", undefined, React.createElement("h3", undefined, "ReasonReact"), React.createElement(Sidebar$SourceLinks, { })), React.createElement("hr", undefined), React.createElement(Sidebar$SpeechBubble, { }), React.createElement("hr", undefined), React.createElement("h4", undefined, "Official Resources"), React.createElement(Sidebar$OfficialResourceLinks, { }), React.createElement("h4", undefined, "Community"), React.createElement(Sidebar$CommunityLinks, { }), React.createElement("footer", undefined, React.createElement("hr", undefined), React.createElement("em", undefined, "If you have other helpful links to share, or find any of the links above no longer work, please ", React.createElement("a", {
                                href: Constant$ReasonTodomvc.home
                              }, "let us know"), ".")));
      }));

exports.Styles = Styles;
exports.SourceLinks = SourceLinks;
exports.SpeechBubble = SpeechBubble;
exports.OfficialResourceLinks = OfficialResourceLinks;
exports.CommunityLinks = CommunityLinks;
exports.make = make;
/* container Not a pure module */
