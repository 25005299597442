'use strict';


var home = "https://github.com/cometkim/reason-todomvc";

var repo = "https://github.com/cometkim/reason-todomvc.git";

exports.home = home;
exports.repo = repo;
/* No side effect */
