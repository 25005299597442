'use strict';

var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");

function tToJs(param) {
  return {
          init: param.init,
          todos: param.todos
        };
}

function tFromJs(param) {
  return {
          init: param.init,
          todos: param.todos
        };
}

function reducer(state, action) {
  if (state.init) {
    if (typeof action === "number") {
      return {
              init: state.init,
              todos: Belt_List.keep(state.todos, (function (todo) {
                      return !todo.complete;
                    }))
            };
    } else {
      switch (action.tag | 0) {
        case /* Init */0 :
            return state;
        case /* AddTodo */1 :
            return {
                    init: state.init,
                    todos: Pervasives.$at(state.todos, /* :: */[
                          action[0],
                          /* [] */0
                        ])
                  };
        case /* ToggleTodo */2 :
            var id = action[0];
            return {
                    init: state.init,
                    todos: Belt_List.map(state.todos, (function (todo) {
                            if (todo.id === id) {
                              return {
                                      id: todo.id,
                                      text: todo.text,
                                      complete: !todo.complete
                                    };
                            } else {
                              return todo;
                            }
                          }))
                  };
        case /* ToggleAll */3 :
            var complete = action[0];
            return {
                    init: state.init,
                    todos: Belt_List.map(state.todos, (function (todo) {
                            return {
                                    id: todo.id,
                                    text: todo.text,
                                    complete: complete
                                  };
                          }))
                  };
        case /* DestroyTodo */4 :
            var id$1 = action[0];
            return {
                    init: state.init,
                    todos: Belt_List.keep(state.todos, (function (todo) {
                            return todo.id !== id$1;
                          }))
                  };
        case /* ChangeText */5 :
            var text = action[1];
            var id$2 = action[0];
            return {
                    init: state.init,
                    todos: Belt_List.map(state.todos, (function (todo) {
                            if (todo.id === id$2) {
                              return {
                                      id: todo.id,
                                      text: text,
                                      complete: todo.complete
                                    };
                            } else {
                              return todo;
                            }
                          }))
                  };
        
      }
    }
  } else if (typeof action === "number" || action.tag) {
    return state;
  } else {
    return {
            init: true,
            todos: action[0].todos
          };
  }
}

var todoDispatchContext = React.createContext((function (param) {
        return /* () */0;
      }));

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = todoDispatchContext.Provider;

function use(param) {
  return React.useContext(todoDispatchContext);
}

var Dispatch = {
  todoDispatchContext: todoDispatchContext,
  makeProps: makeProps,
  make: make,
  use: use
};

var Todo = /* alias */0;

exports.Todo = Todo;
exports.tToJs = tToJs;
exports.tFromJs = tFromJs;
exports.reducer = reducer;
exports.Dispatch = Dispatch;
/* todoDispatchContext Not a pure module */
