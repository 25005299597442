'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Form$ReasonTodomvc = require("./components/Form.bs.js");
var Title$ReasonTodomvc = require("./components/Title.bs.js");
var Footer$ReasonTodomvc = require("./components/Footer.bs.js");
var HexoId$ReasonTodomvc = require("./HexoId.bs.js");
var IndexJs = require("hexoid/dist/index.js");
var Sidebar$ReasonTodomvc = require("./components/Sidebar.bs.js");
var TodoList$ReasonTodomvc = require("./components/TodoList.bs.js");
var Model_AppState$ReasonTodomvc = require("./models/Model_AppState.bs.js");
var TodoController$ReasonTodomvc = require("./components/TodoController.bs.js");

var id = IndexJs(8);

var container = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.background(/* `hex */[
              5194459,
              "fff"
            ]),
        /* :: */[
          Css.marginTop(/* `px */[
                25096,
                130
              ]),
          /* :: */[
            Css.marginBottom(/* `px */[
                  25096,
                  40
                ]),
            /* :: */[
              Css.unsafe("boxShadow", "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1)"),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var main = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.borderTop(/* `px */[
              25096,
              1
            ], /* solid */12956715, /* `hex */[
              5194459,
              "e6e6e6"
            ]),
        /* :: */[
          Css.zIndex(2),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  container: container,
  main: main
};

var defaultState = {
  init: false,
  todos: /* [] */0
};

function App(Props) {
  var match = React.useReducer(Model_AppState$ReasonTodomvc.reducer, defaultState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (state.init) {
            Belt_Option.map(JSON.stringify(state), (function (json) {
                    localStorage.setItem("todo-state", json);
                    return /* () */0;
                  }));
          } else {
            Curry._1(dispatch, /* Init */Block.__(0, [Belt_Option.getWithDefault(Caml_option.nullable_to_opt((JSON.parse(localStorage.getItem('todo-state')))), defaultState)]));
          }
          return ;
        }), [state]);
  var todos = state.todos;
  var hasTodos = Belt_List.length(todos) > 0;
  var onSubmit = React.useCallback((function (v) {
          if (v !== "") {
            return Curry._1(dispatch, /* AddTodo */Block.__(1, [{
                            id: HexoId$ReasonTodomvc.make(id),
                            text: v,
                            complete: false
                          }]));
          } else {
            return 0;
          }
        }), [dispatch]);
  return React.createElement(React.Fragment, undefined, React.createElement(Sidebar$ReasonTodomvc.make, { }), React.createElement("section", {
                  className: container
                }, React.createElement("header", undefined, React.createElement(Title$ReasonTodomvc.make, { }), React.createElement(Form$ReasonTodomvc.make, {
                          onSubmit: onSubmit
                        })), React.createElement(Model_AppState$ReasonTodomvc.Dispatch.make, Model_AppState$ReasonTodomvc.Dispatch.makeProps(dispatch, null, /* () */0), React.createElement("main", {
                          className: main,
                          hidden: !hasTodos
                        }, React.createElement(TodoList$ReasonTodomvc.make, {
                              todos: todos
                            })), React.createElement("footer", {
                          hidden: !hasTodos
                        }, React.createElement(TodoController$ReasonTodomvc.make, {
                              todos: todos
                            })))), React.createElement(Footer$ReasonTodomvc.make, { }));
}

var AppState = /* alias */0;

var make = App;

exports.AppState = AppState;
exports.id = id;
exports.Styles = Styles;
exports.defaultState = defaultState;
exports.make = make;
/* id Not a pure module */
