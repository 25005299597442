'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");

var title = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* `px */[
              25096,
              -155
            ]),
        /* :: */[
          Css.width(Css.pct(100.0)),
          /* :: */[
            Css.fontSize(/* `px */[
                  25096,
                  100
                ]),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    100
                  ]),
              /* :: */[
                Css.textAlign(/* center */98248149),
                /* :: */[
                  Css.color(Css.rgba(175, 47, 47, 0.15)),
                  /* :: */[
                    Css.unsafe("textRendering", "optimizeLegibility"),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  title: title
};

var make = React.memo((function (Props) {
        return React.createElement("h1", {
                    className: title
                  }, "todos");
      }));

exports.Styles = Styles;
exports.make = make;
/* title Not a pure module */
